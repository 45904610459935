import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Col } from "reactstrap";
import classes from "./SimpleForm.module.css"
import Button from "../../../../components/Button/Button";
import CustomInput from "../../../../components/CustomInput/CustomInput";
import 'antd/dist/antd.css';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFunctions, httpsCallable } from "firebase/functions";

// Initialize Firebase
let app, analytics, functions, leadsCreateFromForm;
fetch('/__/firebase/init.json').then(async response => {
    let firebaseConfig = await response.json();
    if (!('appId' in firebaseConfig)) {
        // only for localhost development
        firebaseConfig.appId = "1:1028851891185:web:ad852e19384d0ffd172250";
    }
    app = initializeApp(firebaseConfig);
    analytics = getAnalytics(app);
    functions = getFunctions(app);
    leadsCreateFromForm = httpsCallable(functions, 'leads-createFromForm');
});


const SimpleForm = (props) => {
    const [inputField, setInputField] = useState({
        fullname: '',
        email: '',
        message: ''
    })

    const inputsHandler = (event) => {
        let key = event.target.name;
        let value = event.target.value;
        setInputField(prevState => ({
            ...prevState,
            [key]: value
        }));
    }

    const onFormClickHandler = async (event) => {
        event.preventDefault();

        if (!!inputField.fullname && !!inputField.email) {
            const data = {
                firstName: inputField.fullname,
                lastName: inputField.fullname,
                email: inputField.email,
                inquiry: inputField.message
            };

            leadsCreateFromForm(data).then((result) => {
                // Read result of the Cloud Function.
                /** @type {any} */
                const data = result;
            }).catch((error) => {
                // Getting the Error details.
                const code = error.code;
                const message = error.message;
                const details = error.details;
                // ...
            });

            [...event.currentTarget].forEach(element => element.disabled = true);
            document.querySelector('p[hidden]').hidden = false;
        }
    }

    return (
        <React.Fragment>
            <Form className={classes.form} onSubmit={onFormClickHandler}>
                <Form.Row>
                    <CustomInput
                        classes={`col-lg-6 col-md-6  ${classes.p15} ${classes.item} ${props.classes}`}
                        controlId="FormInputName"
                        name="fullname"
                        value={inputField.fullname}
                        dark={(props.dark === true) ? true : null}
                        placeholder="Full name"
                        simple={true}
                        onChange={inputsHandler}
                    />
                    <CustomInput
                        classes={`col-lg-6 col-md-6  ${classes.p15} ${classes.item} ${props.classes}`}
                        controlId="FormInputUserEmail"
                        name="email"
                        dark={(props.dark === true) ? true : null}
                        placeholder="Email"
                        simple={true}
                        type="email"
                        onChange={inputsHandler}
                    />
                    <CustomInput
                        classes={`col-12 ${classes.p15} ${classes.item} ${props.classes}`}
                        size="big"
                        controlId="Textarea"
                        name="message"
                        dark={(props.dark === true) ? true : null}
                        placeholder="Message"
                        textarea={true}
                        rows={5}
                        onChange={inputsHandler}
                    />
                </Form.Row>

                <Form.Row>
                    <Col className={`col-lg-6 col-md-6 ${classes.p20}`}>
                        <Button
                            type={"submit"}
                            classes={"primary large simple w-100"}
                            value={"Send"}
                        />
                    </Col>
                    <Col className={`col-lg-6 col-md-6 ${classes.p20}`}>
                        <p className={classes.sent} hidden>Your message was sent successfully!</p>
                    </Col>
                </Form.Row>
            </Form>
        </React.Fragment>
    )
}

export default SimpleForm