import React from "react";
import classes from "./CactusMenuSection.module.css"
import Feature from "./Feature/Feature";
import { Container, Col, Row } from "reactstrap";
import { Bookmark, Home, Basket } from "../../../components/Icons/Icons";
import Frame from "../../../components/Frame/Frame";
import { Fade } from "react-awesome-reveal";
import cactusMenuBrowserScreen from '../../../img/cactusMenuBrowserScreen.png';
import metaballIcon from "../../../img/metaball_principal_alpha.png"

const title = "Your menu the way you want it";

function CactusMenuSection(props) {

  return (
    <React.Fragment>
      <section className={`section ${classes.features}${(props.dark) ? ` dark ${classes.dark}` : ''}`} id="menu" name="menu">
        <Fade triggerOnce={true}>
          <img
            src={metaballIcon}
            alt=""
            className={`${classes.bgImg} position-absolute`}
          />
        </Fade>
        <div className={classes.gridWrapper}>
          <Container>
            <Row className={classes.headerRow}>
              <Col lg={8} md={10} className="mx-auto">
                <div className={classes.gridWrapper}>
                  <Fade top triggerOnce={true}>
                    <h1 className={`title text-center ${classes.title}`}>{title}</h1>
                  </Fade>
                </div>
              </Col>
            </Row>

            <Row className={classes.contentRow}>
              <Col lg={6} md={12} className={classes.leftCol}>
                <div className={classes.feature}>
                  <Fade down triggerOnce={true}>
                    <Feature
                      title={"Quick updates"}
                      description={"Configurable menu using your branding, products and categories."}
                    >
                      <Frame variant={"normal"}>
                        <Bookmark />
                      </Frame>
                    </Feature>
                  </Fade>
                </div>

                <div className={classes.feature}>
                  <Fade down delay={50} triggerOnce={true}>
                    <Feature
                      title={"Easy updates"}
                      description={"Updating your menu is as easy as writing text."}
                    >
                      <Frame variant={"normal"}>
                        <Home />
                      </Frame>
                    </Feature>
                  </Fade>
                </div>

                <div className={classes.feature}>
                  <Fade down delay={100} triggerOnce={true}>
                    <Feature
                      title={"Anytime updates"}
                      description={"As long as your phone has internet, you can update your menu."}
                    >
                      <Frame variant={"normal"}>
                        <Basket />
                      </Frame>
                    </Feature>
                  </Fade>
                </div>

              </Col>

              <Col lg={6} md={12} className="d-flex justify-content-center">
                <Fade down delay={250} triggerOnce={true}>
                  <img src={cactusMenuBrowserScreen} alt="" className={classes.imgScreen} />
                </Fade>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </React.Fragment>
  )
}
export default CactusMenuSection