import React from "react";
import { Col } from "reactstrap";
import classes from "./CTATypeFirst.module.css"
import { Fade } from "react-awesome-reveal";

const CTATypeFirst = (props) => {
    return (
        <div className={`${classes.cta} ${(props.dark) ? `dark ${classes.dark}` : ''}`}>
            <Col className={classes.col}>
                <Fade top delay={50} triggerOnce={true}>
                    <h1>{props.title}</h1>
                </Fade>
                <Fade top delay={100} triggerOnce={true}>
                    <p className={`normal-text ${classes.description}`}>{props.description}</p>
                </Fade>
            </Col>
        </div>
    )
}

export default CTATypeFirst