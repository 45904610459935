import React from "react";
import { Container, Col, Row } from "reactstrap";
import { CactusLogotype } from "../../../components/Icons/Icons";
import { Nav, Navbar } from "react-bootstrap";
import { Fade } from "react-awesome-reveal";
import { AppStoreBadge, PlayStoreBadge } from "../../../components/Badges/Badges";
import Divider from "../../../components/Divider/Divider";
import classes from "./CactusFooterSection.module.css"
import metaballIcon from "../../../img/metaball_principal_alpha.png"

function CactusFooterSection(props) {
    return (
        <React.Fragment>
            <section className={`section ${classes.footer}${(props.dark) ? ` dark ${classes.dark}` : ''}`}>
                <Fade triggerOnce={true}>
                    <img
                        src={metaballIcon}
                        alt=""
                        className={`${classes.bgImg} position-absolute`}
                    />
                </Fade>
                <div className={classes.gridWrapper}>
                    <Container>
                        <Row>
                            <Col>
                                <Navbar expand="lg" className={`p-0 justify-content-between ${classes.navbar}`}>
                                    <Navbar.Brand href="#home" className={classes.navbarBrand}>
                                        <Fade top triggerOnce={true} >
                                            <CactusLogotype />
                                        </Fade>
                                    </Navbar.Brand>
                                    <Nav className={classes.navbarNav}>
                                        <Fade top delay={50} triggerOnce={true}>
                                            <Nav.Link href="#staff" className={classes.navLink}>Cactus Staff</Nav.Link>
                                            <Nav.Link href="#menu" className={classes.navLink}>Menu</Nav.Link>
                                            <Nav.Link href="#pricing" className={classes.navLink}>Pricing</Nav.Link>
                                            <Nav.Link href="#contact" className={classes.navLink}>Contact</Nav.Link>
                                        </Fade>
                                    </Nav>
                                    <div className={classes.socials}>
                                        <Fade top delay={100} triggerOnce={true}>
                                            <AppStoreBadge />
                                            <PlayStoreBadge />
                                        </Fade>
                                    </div>
                                </Navbar>
                            </Col>
                        </Row>

                        <Row className={classes.dividerRow}>
                            <Col>
                                <Fade top delay={150} triggerOnce={true}>
                                    <Divider />
                                </Fade>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-auto mx-auto">
                                <Fade top delay={200} triggerOnce={true}>
                                    <p className={classes.copyright}>Copyright © 2021 Opame Inc.</p>
                                </Fade>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        </React.Fragment>
    )
}
export default CactusFooterSection