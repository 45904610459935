import React from "react";
import classes from "./Header.module.css"
import { Nav, Navbar } from "react-bootstrap";
import { CactusLogotype } from "../../../../components/Icons/Icons";
import { Input } from 'antd';

const Header = (props) => {
    return (
        <section className={`${classes.header} ${(props.dark) ? `dark ${classes.dark}` : ''}`}>
            <Navbar collapseOnSelect expand="lg" className="p-0 position-relative">
                <div className={classes.wrapper}>
                    <Navbar.Brand href="#home">
                        <CactusLogotype />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" className="custom-toggler" />
                </div>
                <Navbar.Collapse id="responsive-navbar-nav" role='navigation' className={classes.navCollapse} style={{ height: '0' }}>
                    <Nav>
                        <Nav.Link href="#staff">Cactus Staff</Nav.Link>
                        <Nav.Link href="#menu">Menu</Nav.Link>
                        <Nav.Link href="#pricing">Pricing</Nav.Link>
                        <Nav.Link href="#contact">Contact</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </section>
    )
}

export default Header