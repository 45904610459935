import { Row, Col, Container } from "reactstrap";
import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import BaseSwitch from "../../../components/Base-Switcher/Base-Switcher";
import Button from "../../../components/Button/Button";
import { Fade } from "react-awesome-reveal";
import Pricing from "./Pricing/PricingTypeFourth";
import classes from "./CactusPricingSection.module.css"
import qrRestaurat from "../../../img/qrc-restaurant.png"

const title = `One plan. For Free.`;
const description = `Configure your menu. Zero cost. As long as you want.`;
const linkText = `Sign Up`;

class CactusPricingSection extends Component {

    state = {
        activePrice: {
            true: 'free',
            false: 'yearly'
        },

        pricing: [
            {
                type: 'Cactus Menu subscription',
                id: 1,
                description: 'All features included',
                pricing: {
                    free: 0.00,
                    yearly: 7.95
                },
                links: {
                    free: 'https://buy.stripe.com/14k8AgdJCfqN4o0bIL',
                    yearly: 'https://buy.stripe.com/14k8AgdJCfqN4o0bIL'
                }
            }
        ]
    }

    onLinksClickHandler = (event) => {
        this.setState({ activeToggle: !this.state.activeToggle });
    }

    cardClickHandler = (event) => {
        const currentId = event.target.closest("div[class*=\"find\"]").id;
        this.setState({ activeIndex: currentId });
    }

    renderCards() {
        const card = this.state.pricing;
        const listItems = card.map((item, index) => {
            // {if(item.id == this.state.activeIndex) {
            return (
                // <Col lg="6" md="6" className={classes.item} key={index}>
                <Pricing
                    title={item.type}
                    description={item.description}
                    price="0.00"
                    id={item.id}
                    classes={'active'}
                    onClick={this.cardClickHandler}
                    dark={this.props.dark ? true : false}
                />
                // </Col>
            )
            // } else {
            //     return (
            //         <Col lg="6" md="6" className={classes.item} key={index}>
            //             <Pricing
            //                 title={item.type}
            //                 description={item.description}
            //                 price={
            //                     item.pricing[this.state.activePrice[this.state.activeToggle]]
            //                 }
            //                 id={item.id}
            //                 classes={''}
            //                 onClick={this.cardClickHandler}
            //                 dark={this.props.dark ? true : false}
            //             />
            //         </Col>
            //     )
            // }
            //}
        });
        return (
            <>
                {listItems}
            </>
        );
    }

    render() {
        return (
            <section className={`section ${classes.pricing}${(this.props.dark) ? ` dark ${classes.dark}` : ''}`} id="pricing" name="pricing">
                <div className={classes.wrapper} >
                    <Container>
                        <Row>
                            <Col lg={5} md={12} className="d-flex justify-content-center align-items-center">
                                <Fade triggerOnce={true}>
                                    <img
                                        src={qrRestaurat}
                                        alt=""
                                        className={classes.leftImg}
                                    />
                                </Fade>
                            </Col>
                            <Col lg={7} md={12} className={classes.rightCol}>
                                <Row>
                                    <Col lg="8" md="8" className="mx-auto">
                                        <Fade top triggerOnce={true}>
                                            <h1 className="text-center">{title}</h1>
                                        </Fade>
                                        <Fade top delay={50} triggerOnce={true}>
                                            <p className={`normal-text ${classes.description} text-center`}>{description}</p>
                                        </Fade>
                                    </Col>
                                </Row>
                                
                                <Fade top delay={200} triggerOnce={true}>
                                    <Row className={classes.pricingRow}>
                                        <Col lg="8" md="8" className="mx-auto">
                                            {/* <Row> */}
                                            {this.renderCards()}
                                            {/* </Row> */}
                                        </Col>
                                    </Row>
                                </Fade>

                                <Row>
                                    <Col lg="8" md="8" className={`mx-auto ${classes.link}`}>
                                        <Fade top delay={250} triggerOnce={true}>
                                            <Button
                                                link={true}
                                                href="https://form.jotform.com/213266925352659"
                                                classes={"primary big simple"}
                                                value={linkText}
                                            />
                                        </Fade>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        )
    }
}

export default CactusPricingSection