import React from "react";
import './Divider.css';

const Divider = (props) => {
    return (
        <div className={`Divider ${props.types}`}>

        </div>
    )
}

export default Divider