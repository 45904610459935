import React from 'react';
import CactusHeroSection from "./layouts/cactus/CactusHeroSection/CactusHeroSection";
import CactusStaffAppSection from "./layouts/cactus/CactusStaffAppSection/CactusStaffAppSection";
import CactusMenuSection from "./layouts/cactus/CactusMenuSection/CactusMenuSection";
import CactusPricingSection from "./layouts/cactus/CactusPricingSection/CactusPricingSection";
import CactusContactSection from "./layouts/cactus/CactusContactSection/CactusContactSection";
import CactusFooterSection from "./layouts/cactus/CactusFooterSection/CactusFooterSection";

function App() {
    return (
        <div>
            <CactusHeroSection />
            <CactusStaffAppSection />
            <CactusMenuSection />
            <CactusPricingSection />
            <CactusContactSection />
            <CactusFooterSection />
        </div>
    );
}

export default App;